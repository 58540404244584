<template>
  <div class="container">
    <div class="page-sub-box ewm">
      <div class="locker_setting_list sub_new_style01 sub_ui_box1">
        <div class="page_search_box line_bottom_1px">
          <div class="flex space-x-2 inner">
            <div class="mt-2">기준년월</div>
            <DxDateBox
              :styling-mode="stylingMode"
              width="100"
              height="30"
              v-model="searchType.customTypes.dayStart"
              type="date"
              dateSerializationFormat="yyyyMMdd"
              display-format="yyyy.MM"
              date-out-of-range-message="시작일은 종료일보다 작거나 같아야 합니다."
              invalid-date-message="입력 데이터가 유효하지 않습니다."
              :calendar-options="{
                zoomLevel: 'year',
                minZoomLevel: 'year',
                maxZoomLevel: 'year',
              }"
              @value-changed="onChangePeriod"
            >
              <DxValidator validation-group="validationSearch">
                <DxRequiredRule message="기준년월은 필수입니다." />
              </DxValidator>
            </DxDateBox>
            <div class="mt-2 pl-2">생산성계획</div>
            <div>
              <DxTextBox :styling-mode="textStylingMode" v-model="modal.scheNm" :width="120" :height="30" :read-only="true">
                <DxValidator validation-group="validationSearch">
                  <DxRequiredRule message="생산성계획 선택은 필수입니다." />
                </DxValidator>
              </DxTextBox>
            </div>
            <div>
              <DxButton icon="search" :width="40" :height="30" @click="chooseKpiSche" />
            </div>
            <div class="mt-2">스케줄 적용여부</div>
            <div class="mt-1">
              <DxCheckBox class="checkbox" :value="modal.agtScheApply" :disabled="true" />
            </div>
            <div class="pl-2">
              <DxButton text="진행" :height="30" class="btn_XS default filled" @click="onProgress" />
            </div>
            <div>
              <DxButton text="검색" class="btn_M box-btn-search" type="button" :height="30" @click="selectDataList" />
            </div>
          </div>
        </div>
      </div>

      <div class="gap-y-6">
        <div class="max-h-5/12">
          <esp-dx-data-grid :data-grid="kpiStdGrid" ref="kpiStdGrid" />
        </div>
        <div class="h-7/12">
          <esp-dx-data-grid :data-grid="resultGrid" ref="resultGrid" />
        </div>
      </div>
    </div>
    <!-- Modal Layer -->
    <DxPopup
      :show-title="true"
      :title="modal.initData ? modal.initData.title : null"
      :min-width="modal.initData ? modal.initData.width : null"
      :width="modal.initData ? modal.initData.width : null"
      :min-height="modal.initData ? modal.initData.height : null"
      :height="modal.initData ? modal.initData.height : null"
      :drag-enabled="true"
      :resize-enabled="true"
      :show-close-button="true"
      :close-on-outside-click="false"
      v-model="modal.isOpened"
      :visible="modal.isOpened"
      @hiding="isOpenModal(false)"
    >
      <template #content>
        <div>
          <component :is="modal.currentComponent" :contentData="modal.contentData" v-model="modal.contentData"></component>
        </div>
      </template>
      <DxToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="center"
        :visible="
          modal.initData.hasOwnProperty('buttons')
            ? modal.initData.buttons.hasOwnProperty('save')
              ? modal.initData.buttons.hasOwnProperty('save')
              : false
            : false
        "
        :options="{
          elementAttr: {
            class: 'default filled txt_S medium',
          },
          text: modal.initData.hasOwnProperty('buttons')
            ? modal.initData.buttons.hasOwnProperty('save')
              ? modal.initData.buttons.save.text
              : ''
            : '',
          width: modal.initData.hasOwnProperty('buttons')
            ? modal.initData.buttons.hasOwnProperty('save')
              ? modal.initData.buttons.save.width
              : 120
            : 120,
          height: modal.initData.hasOwnProperty('buttons')
            ? modal.initData.buttons.hasOwnProperty('save')
              ? modal.initData.buttons.save.height
              : 40
            : 40,
          onClick: () => {
            onConfirmModal();
          },
        }"
      />
      <DxToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="center"
        :visible="
          modal.initData.hasOwnProperty('buttons')
            ? modal.initData.buttons.hasOwnProperty('cancel')
              ? modal.initData.buttons.hasOwnProperty('cancel')
              : !modal.initData.buttons.hasOwnProperty('cancel')
            : false
        "
        :options="{
          elementAttr: {
            class: 'white filled txt_S medium',
          },
          text: modal.initData.hasOwnProperty('buttons')
            ? modal.initData.buttons.hasOwnProperty('cancel')
              ? modal.initData.buttons.cancel.text
              : ''
            : '',
          width: modal.initData.hasOwnProperty('buttons')
            ? modal.initData.buttons.hasOwnProperty('cancel')
              ? modal.initData.buttons.cancel.width
              : 120
            : 120,
          height: modal.initData.hasOwnProperty('buttons')
            ? modal.initData.buttons.hasOwnProperty('cancel')
              ? modal.initData.buttons.cancel.height
              : 40
            : 40,
          onClick: () => {
            isOpenModal(false);
          },
        }"
      />
    </DxPopup>
    <!-- /Modal Layer -->
  </div>
</template>

<script>
import { DxDateBox } from 'devextreme-vue/date-box';
import { DxTextBox } from 'devextreme-vue/text-box';
import { DxCheckBox } from 'devextreme-vue/check-box';
import { DxButton } from 'devextreme-vue/button';
import { DxValidator, DxRequiredRule } from 'devextreme-vue/validator';
import { DxValidationGroup } from 'devextreme-vue/validation-group';
import validationEngine from 'devextreme/ui/validation_engine';
import { getPastFromToday, isSuccess } from '@/plugins/common-lib';
import { DxPopup, DxToolbarItem } from 'devextreme-vue/popup';

import ModalKpiSche from '@/pages/ewm/productivity/report/result/modal-kpi-sche';
import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid.vue';

let vm = null;

export default {
  components: {
    EspDxDataGrid,
    DxDateBox,
    DxTextBox,
    DxCheckBox,
    DxButton,

    DxRequiredRule,
    DxValidator,
    DxValidationGroup,

    DxPopup,
    DxToolbarItem,

    ModalKpiSche,
  },
  props: {},
  watch: {},
  data() {
    return {
      textStylingMode: 'filled',
      stylingMode: 'outlined', //outlined, underlined, filled
      searchType: {
        obj: {},
        defaultObj: { id: 'ROOT', codeValue: 'ROOT', codeNm: '전체' },
        defaultValue: 'ROOT',
        customTypes: {
          dayStart: getPastFromToday(0, 'months'),
          // dayEnd: getPastFromToday(-5, 'months'),
          targetName: '',
          targetId: '',
        },
        paramsData: null,
      },
      codes: {
        productionTypeCd: {
          dataSource: [],
          displayExpr: 'codeNm',
          valueExpr: 'codeId',
        },
      },
      modal: {
        isOpened: false,
        initData: {},
        contentData: null,
        componentName: '',
        agtScheApply: false,
        scheId: null,
        scheNm: null,
      },
      kpiStdGrid: {
        keyExpr: 'id',
        refName: 'kpiStdGrid',
        allowColumnResizing: true,
        columnResizingMode: 'widget',
        columnAutoWidth: true,
        showBorders: false, //border 유무
        showColumnHeaders: true, //컬럼 헤더 유무
        showColumnLines: false, //컬럼 세로선 유무
        showRowLines: true, //컬럼 가로선 유무
        rowAlternationEnabled: false,
        // width:'200',     // 주석처리시 100%
        // height: 'calc(100vh - 270px)', // 주석처리시 100%
        dataSource: [],
        scrolling: {
          mode: 'standard',
        },
        callApi: 'CALL_EWM_API',
        apiActionNm: {},
        customEvent: {},
        showActionButtons: {
          customButtons: [],
        },
        grouping: {
          contextMenuEnabled: false,
          autoExpandAll: false,
          allowCollapsing: true,
          expandMode: 'rowClick', // rowClick or buttonClick
        },
        groupPanel: {
          visible: false,
        },
        columnChooser: {
          enabled: false, // 컬럼 Chooser 버튼 사용유무
        },
        loadPanel: {
          enabled: true, // 로딩바 표시 유무
        },
        sorting: {
          mode: 'multiple', // single multiple
        },
        remoteOperations: {
          // 서버사이드 여부
          filtering: false,
          sorting: false,
          grouping: false,
          paging: false,
        },
        paging: {
          enabled: false,
          pageSize: 10,
          pageIndex: 0, // 시작페이지
        },
        pager: {
          visible: false, //페이저 표시 여부
          showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
          allowedPageSizes: [],
          displayMode: 'compact', //표시 모드 : ['full', 'compact']
          showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
          showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
        },
        filterRow: {
          visible: true,
        },
        headerFilter: {
          visible: true,
        },
        editing: {
          allowUpdating: false, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
          allowAdding: false, // 추가 버튼을 없애고 싶으면 false설정
          allowDeleting: false,
          mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
          startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
          selectTextOnEditStart: false, //셀 수정시 텍스트 전체 선택 여부
        },
        /* selecting: {
        mode: 'single', //행 단일/멀티 선택 타입 : ['single', 'multiple']
        selectAllMode: 'allPages', //행 선택 허용 범위 : ['allPages', 'page']
        showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
      }, */
        columns: [
          {
            caption: '구분',
            dataField: 'productionTypeCd',
            alignment: 'center',
            visible: true,
            allowEditing: false,
            allowHeaderFiltering: false,
          },
          {
            caption: '평가기준명',
            dataField: 'productionName',
            alignment: 'left',
            visible: true,
            allowEditing: false,
            allowHeaderFiltering: false,
          },
          {
            caption: 'IB(%)',
            dataField: 'inCntWeight',
            alignment: 'center',
            visible: false,
            allowEditing: false,
            allowHeaderFiltering: false,
          },
          {
            caption: 'OB(%)',
            dataField: 'outCntWeight',
            alignment: 'center',
            visible: false,
            allowEditing: false,
            allowHeaderFiltering: false,
          },
          {
            multiHeaderNm: '가중치',
            columns: [
              {
                caption: 'IB(%)',
                dataField: 'inTimeWeight',
                alignment: 'center',
                visible: true,
                width: 120,
                allowEditing: false,
                allowHeaderFiltering: false,
                calculateSortValue: rowData => {
                  if (rowData.productionTypeCd == 1274) {
                    return rowData.inCntWeight;
                  } else {
                    return rowData.inTimeWeight;
                  }
                },
                calculateDisplayCellValue: rowData => {
                  if (rowData.productionTypeCd == 1274) {
                    return `${rowData.inCntWeight} %`;
                  } else {
                    return `${rowData.inTimeWeight} %`;
                  }
                },
              },
              {
                caption: 'OB(%)',
                dataField: 'outTimeWeight',
                alignment: 'center',
                visible: true,
                width: 120,
                allowEditing: false,
                allowHeaderFiltering: false,
                calculateSortValue: rowData => {
                  if (rowData.productionTypeCd == 1274) {
                    return rowData.outCntWeight;
                  } else {
                    return rowData.outTimeWeight;
                  }
                },
                calculateDisplayCellValue: rowData => {
                  if (rowData.productionTypeCd == 1274) {
                    return `${rowData.outCntWeight} %`;
                  } else {
                    return `${rowData.outTimeWeight} %`;
                  }
                },
              },
            ],
          },
          {
            caption: '평가방식',
            dataField: 'evaluationMethod',
            alignment: 'left',
            visible: true,
            allowEditing: false,
            allowHeaderFiltering: false,
          },
          {
            caption: '배점',
            dataField: 'productionScore',
            alignment: 'center',
            visible: true,
            width: 110,
            allowEditing: false,
            allowHeaderFiltering: false,
          },
        ],
      },
      originColumns: [
        {
          caption: '부서정보',
          dataField: 'deptNmPath',
          alignment: 'center',
          visible: true,
          allowEditing: false,
          allowHeaderFiltering: false,
          fixed: true,
        },
        {
          caption: '상담사',
          dataField: 'agtId',
          alignment: 'center',
          width: 120,
          visible: false,
          allowEditing: false,
          allowHeaderFiltering: false,
          fixed: true,
        },
        {
          caption: '상담사',
          dataField: 'agtIdAndNm',
          alignment: 'center',
          width: 120,
          visible: true,
          allowEditing: false,
          allowHeaderFiltering: false,
          calculateSortValue: data => `${data.agtNm} [${data.agtId}]`,
          calculateCellValue: rowData => {
            return `${rowData.agtNm} [${rowData.agtId}]`;
          },
          fixed: true,
        },
        {
          caption: '총근무시간',
          dataField: 'workTime',
          alignment: 'center',
          width: 110,
          visible: true,
          allowEditing: false,
          allowHeaderFiltering: false,
          fixed: true,
        },
        {
          caption: '기준일수',
          dataField: 'workBaseYmd',
          alignment: 'center',
          width: 110,
          visible: true,
          allowEditing: false,
          allowHeaderFiltering: false,
          fixed: true,
        },
        {
          caption: '총통화건수',
          dataField: 'ans',
          alignment: 'center',
          width: 110,
          visible: true,
          allowEditing: false,
          allowHeaderFiltering: false,
        },
        {
          caption: '총통화시간',
          dataField: 'talkTime',
          alignment: 'center',
          visible: true,
          allowEditing: false,
          allowHeaderFiltering: false,
        },
        {
          caption: 'I/B',
          dataField: 'dnTalkTime',
          alignment: 'center',
          visible: true,
          allowEditing: false,
          allowHeaderFiltering: false,
        },
        {
          caption: 'O/B',
          dataField: 'obExTalkTime',
          alignment: 'center',
          visible: true,
          allowEditing: false,
          allowHeaderFiltering: false,
        },
        {
          multiHeaderNm: '내선전화',
          columns: [
            {
              caption: 'IN',
              dataField: 'dnAns',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowHeaderFiltering: false,
            },
            {
              caption: 'OB',
              dataField: 'obInConn',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowHeaderFiltering: false,
            },
          ],
        },
        {
          caption: '외선 OB',
          dataField: 'obExConn',
          alignment: 'center',
          visible: true,
          allowEditing: false,
          allowHeaderFiltering: false,
        },
        {
          caption: '호전환 건수',
          dataField: 'transout',
          alignment: 'center',
          visible: true,
          allowEditing: false,
          allowHeaderFiltering: false,
        },
        {
          caption: '대기',
          dataField: 'readyTime',
          alignment: 'center',
          visible: true,
          allowEditing: false,
          allowHeaderFiltering: false,
        },
        {
          caption: '후처리',
          dataField: 'acwTime',
          alignment: 'center',
          visible: true,
          allowEditing: false,
          allowHeaderFiltering: false,
        },
        {
          multiHeaderNm: '이석',
          columns: [
            {
              caption: '이석1',
              dataField: 'nrsn1Time',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowHeaderFiltering: false,
            },
            {
              caption: '이석2',
              dataField: 'nrsn2Time',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowHeaderFiltering: false,
            },
            {
              caption: '이석3',
              dataField: 'nrsn3Time',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowHeaderFiltering: false,
            },
            {
              caption: '이석4',
              dataField: 'nrsn4Time',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowHeaderFiltering: false,
            },
            {
              caption: '이석5',
              dataField: 'nrsn5Time',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowHeaderFiltering: false,
            },
            {
              caption: '이석6',
              dataField: 'nrsn6Time',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowHeaderFiltering: false,
            },
            {
              caption: '이석7',
              dataField: 'nrsn7Time',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowHeaderFiltering: false,
            },
            {
              caption: '이석8',
              dataField: 'nrsn8Time',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowHeaderFiltering: false,
            },
            {
              caption: '이석9',
              dataField: 'nrsn9Time',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowHeaderFiltering: false,
            },
            {
              caption: '이석10',
              dataField: 'nrsn10Time',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowHeaderFiltering: false,
            },
          ],
        },
      ],
      methodColumns: [],
      rankColumns: [
        {
          caption: '점수합계',
          dataField: 'totalResultScore',
          alignment: 'center',
          visible: true,
          allowEditing: false,
          allowHeaderFiltering: false,
        },
        {
          caption: '순위',
          dataField: 'resultRank',
          alignment: 'center',
          visible: true,
          allowEditing: false,
          allowHeaderFiltering: false,
        },
      ],
      resultGrid: {
        keyExpr: 'targetId',
        refName: 'resultGrid',
        allowColumnResizing: true,
        columnResizingMode: 'widget',
        columnAutoWidth: true,
        showBorders: false, //border 유무
        showColumnHeaders: true, //컬럼 헤더 유무
        showColumnLines: false, //컬럼 세로선 유무
        showRowLines: true, //컬럼 가로선 유무
        rowAlternationEnabled: false,
        // width:'200',     // 주석처리시 100%
        // height: 'calc(100vh - 270px)', // 주석처리시 100%
        dataSource: [],
        scrolling: {
          mode: 'standard',
        },
        callApi: 'CALL_EWM_API',
        apiActionNm: {},
        customEvent: {},
        showActionButtons: {
          customButtons: [],
        },
        grouping: {
          contextMenuEnabled: false,
          autoExpandAll: false,
          allowCollapsing: true,
          expandMode: 'rowClick', // rowClick or buttonClick
        },
        groupPanel: {
          visible: false,
        },
        columnChooser: {
          enabled: false, // 컬럼 Chooser 버튼 사용유무
        },
        loadPanel: {
          enabled: true, // 로딩바 표시 유무
        },
        sorting: {
          mode: 'multiple', // single multiple
        },
        remoteOperations: {
          // 서버사이드 여부
          filtering: false,
          sorting: false,
          grouping: false,
          paging: false,
        },
        paging: {
          enabled: false,
          pageSize: 10,
          pageIndex: 0, // 시작페이지
        },
        pager: {
          visible: false, //페이저 표시 여부
          showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
          allowedPageSizes: [],
          displayMode: 'compact', //표시 모드 : ['full', 'compact']
          showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
          showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
        },
        filterRow: {
          visible: true,
        },
        headerFilter: {
          visible: true,
        },
        editing: {
          allowUpdating: false, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
          allowAdding: false, // 추가 버튼을 없애고 싶으면 false설정
          allowDeleting: false,
          mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
          startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
          selectTextOnEditStart: false, //셀 수정시 텍스트 전체 선택 여부
        },
        selecting: {
          mode: 'single', //행 단일/멀티 선택 타입 : ['single', 'multiple']
          selectAllMode: 'allPages', //행 선택 허용 범위 : ['allPages', 'page']
          showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
        },
        columns: [
          {
            caption: '부서정보',
            dataField: 'deptNmPath',
            alignment: 'center',
            visible: true,
            allowEditing: false,
            allowHeaderFiltering: false,
            fixed: true,
          },
          {
            caption: '상담사',
            dataField: 'agtId',
            alignment: 'center',
            width: 120,
            visible: true,
            allowEditing: false,
            allowHeaderFiltering: false,
            calculateSortValue: data => `${data.agtNm} [${data.agtId}]`,
            calculateDisplayCellValue: rowData => {
              return `${rowData.agtNm} [${rowData.agtId}]`;
            },
            fixed: true,
          },
          {
            caption: '총근무시간',
            dataField: 'workTime',
            alignment: 'center',
            width: 110,
            visible: true,
            allowEditing: false,
            allowHeaderFiltering: false,
            fixed: true,
          },
          {
            caption: '기준일수',
            dataField: 'workBaseYmd',
            alignment: 'center',
            width: 110,
            visible: true,
            allowEditing: false,
            allowHeaderFiltering: false,
            fixed: true,
          },
          {
            caption: '총통화건수',
            dataField: 'ans',
            alignment: 'center',
            width: 110,
            visible: true,
            allowEditing: false,
            allowHeaderFiltering: false,
          },
          {
            caption: '총통화시간',
            dataField: 'talkTime',
            alignment: 'center',
            visible: true,
            allowEditing: false,
            allowHeaderFiltering: false,
          },
          {
            caption: 'I/B',
            dataField: 'dnTalkTime',
            alignment: 'center',
            visible: true,
            allowEditing: false,
            allowHeaderFiltering: false,
          },
          {
            caption: 'O/B',
            dataField: 'obExTalkTime',
            alignment: 'center',
            visible: true,
            allowEditing: false,
            allowHeaderFiltering: false,
          },
          {
            multiHeaderNm: '내선전화',
            columns: [
              {
                caption: 'IN',
                dataField: 'dnAns',
                alignment: 'center',
                visible: true,
                allowEditing: false,
                allowHeaderFiltering: false,
              },
              {
                caption: 'OB',
                dataField: 'obInConn',
                alignment: 'center',
                visible: true,
                allowEditing: false,
                allowHeaderFiltering: false,
              },
            ],
          },
          {
            caption: '외선 OB',
            dataField: 'obExConn',
            alignment: 'center',
            visible: true,
            allowEditing: false,
            allowHeaderFiltering: false,
          },
          {
            caption: '호전환 건수',
            dataField: 'transout',
            alignment: 'center',
            visible: true,
            allowEditing: false,
            allowHeaderFiltering: false,
          },
          {
            caption: '대기',
            dataField: 'readyTime',
            alignment: 'center',
            visible: true,
            allowEditing: false,
            allowHeaderFiltering: false,
          },
          {
            caption: '후처리',
            dataField: 'acwTime',
            alignment: 'center',
            visible: true,
            allowEditing: false,
            allowHeaderFiltering: false,
          },
          {
            multiHeaderNm: '이석',
            columns: [
              {
                caption: '이석1',
                dataField: 'nrsn1Time',
                alignment: 'center',
                visible: true,
                allowEditing: false,
                allowHeaderFiltering: false,
              },
              {
                caption: '이석2',
                dataField: 'nrsn2Time',
                alignment: 'center',
                visible: true,
                allowEditing: false,
                allowHeaderFiltering: false,
              },
              {
                caption: '이석3',
                dataField: 'nrsn3Time',
                alignment: 'center',
                visible: true,
                allowEditing: false,
                allowHeaderFiltering: false,
              },
              {
                caption: '이석4',
                dataField: 'nrsn4Time',
                alignment: 'center',
                visible: true,
                allowEditing: false,
                allowHeaderFiltering: false,
              },
              {
                caption: '이석5',
                dataField: 'nrsn5Time',
                alignment: 'center',
                visible: true,
                allowEditing: false,
                allowHeaderFiltering: false,
              },
              {
                caption: '이석6',
                dataField: 'nrsn6Time',
                alignment: 'center',
                visible: true,
                allowEditing: false,
                allowHeaderFiltering: false,
              },
              {
                caption: '이석7',
                dataField: 'nrsn7Time',
                alignment: 'center',
                visible: true,
                allowEditing: false,
                allowHeaderFiltering: false,
              },
              {
                caption: '이석8',
                dataField: 'nrsn8Time',
                alignment: 'center',
                visible: true,
                allowEditing: false,
                allowHeaderFiltering: false,
              },
              {
                caption: '이석9',
                dataField: 'nrsn9Time',
                alignment: 'center',
                visible: true,
                allowEditing: false,
                allowHeaderFiltering: false,
              },
              {
                caption: '이석10',
                dataField: 'nrsn10Time',
                alignment: 'center',
                visible: true,
                allowEditing: false,
                allowHeaderFiltering: false,
              },
            ],
          },
        ],
      },
    };
  },
  computed: {
    searchValidationGroup: function () {
      return this.$refs['searchValidationGroup'].instance;
    },
  },
  methods: {
    onChangePeriod() {
      this.modal.scheId = null;
      this.modal.scheNm = null;
    },
    /** @description : 생산성 계획 선택 */
    chooseKpiSche() {
      this.onOpenModal(
        'ModalKpiSche',
        {
          title: '생산성평가계획',
          buttons: {
            save: {text: '선택'},
            cancel: {text: '닫기'},
          },
          width: '40%',
          height: '50%',
        },
        {
          dayStart: this.searchType.customTypes.dayStart,
        },
      );
    },
    onOpenModal(componentNm, componentInitData, data) {
      this.modal.currentComponent = componentNm;
      this.modal.initData = componentInitData;
      this.modal.contentData = data;
      this.isOpenModal(true);
    },
    /** @description: 팝업 오픈 체크 메서드 */
    isOpenModal(data) {
      this.modal.isOpened = data;
      if (!data) {
        this.modal.currentComponent = null;
        this.modal.initData = {};
      }
    },
    async onConfirmModal() {
      if (!this.modal.contentData.val?.length) {
        this.$_Msg(this.$_msgContents('COMMON.MESSAGE.CMN_NOT_SELECTED', {defaultValue: '대상이 선택되어 있지 않습니다.'}));
        return;
      }

      this.modal.agtScheApply = this.modal.contentData.val[0].agtScheApply == 'N' ? false : true;
      this.modal.scheNm = this.modal.contentData.val[0].scheNm;
      this.modal.scheId = this.modal.contentData.val[0].id;

      this.isOpenModal(false);
    },
    async selectDataList() {
      if (!validationEngine.validateGroup('validationSearch').isValid) {
        return;
      }

      const payload1 = {
        actionname: 'EWM_PRODUCTIVITY_SCHEDULE_DETAIL_LIST',
        data: {
          scheId: this.modal.scheId,
        },
        loading: false,
      };

      const res1 = await vm.CALL_EWM_API(payload1);

      if (isSuccess(res1)) {
        this.methodColumns = [];
        res1.data.data[0].scheStds.forEach(d => {
          this.methodColumns.push({
            caption: d.productionName,
            dataField: d.kpiProStdId + '',
            alignment: 'center',
            visible: true,
            allowEditing: false,
            allowHeaderFiltering: false,
          });
        });
        res1.data.data[0].scheStds.forEach(d => {
          this.methodColumns.push({
            caption: d.productionName + ' 환산점수',
            dataField: d.kpiProStdId + 'Score',
            alignment: 'center',
            visible: true,
            allowEditing: false,
            allowHeaderFiltering: false,
          });
        });
        this.kpiStdGrid.dataSource = res1.data.data[0].scheStds;

        const dynamicColumns = [...this.originColumns, ...this.methodColumns, ...this.rankColumns];
        this.$set(this.resultGrid, 'columns', dynamicColumns);

        const payload2 = {
          actionname: 'EWM_PRODUCTIVITY_REPORT_RESULT_LIST',
          data: {
            scheId: this.modal.scheId,
          },
          loading: false,
        };

        const res2 = await vm.CALL_EWM_API(payload2);

        if (isSuccess(res2)) {
          res2.data.data.forEach(item => {
            const resultScore = item.resultScore;
            Object.keys(resultScore).forEach(data => {
              item[data] = resultScore[data];
            });
            delete item.resultScore;
          });
          this.resultGrid.dataSource = res2.data.data;
        }
      }
    },
    async onProgress() {
      if (!validationEngine.validateGroup('validationSearch').isValid) {
        return;
      }

      if (await this.$_Confirm('생산성평가를 진행 하시겠습니까? \n (평가진행된 내역이 있는 경우 삭제 후 진행됩니다.)')) {
        const payload = {
          actionname: 'EWM_PRODUCTIVITY_REPORT_RESULT_SAVE',
          data: {
            startDt: this.searchType.customTypes.dayStart.substring(0, 6) + '01',
            endDt: this.searchType.customTypes.dayStart.substring(0, 6) + '30',
            scheId: this.modal.scheId,
          },
          loading: false,
        };
        const res = await vm.CALL_EWM_API(payload);
        if (isSuccess(res)) {
          this.$_Toast(this.$_msgContents('CMN_SUC_SAVE', {defaultValue: '정상적으로 저장되었습니다.'}));
          this.selectDataList();
        } else {
          this.$_Toast(this.$_msgContents('CMN_ERROR', {defaultValue: '데이터 처리 중 오류가 발생하였습니다.'}));
        }
      }
    },
    /** @description : 코드 로드 */
    async initCodeMap() {
      this.codeMap = await this.$_getCodeMapList('root_ewm_kpi_production_type');
    },
    /** @description : 라이프사이클 created 호출되는 메서드 */
    async createdData() {
      vm = this;

      await this.initCodeMap().then(() => {
        this.codes.productionTypeCd.dataSource = this.$_fetchCodesByDepth(this.codeMap['root_ewm_kpi_production_type'], 2);

        this.$refs.kpiStdGrid.getGridInstance.columnOption('productionTypeCd', 'lookup', this.codes.productionTypeCd);
      });
    },
    /** @description : 라이프사이클 mounted 호출되는 메서드 */
    mountedData() {
    },
  },
  created() {
    this.createdData();
  },
  mounted() {
    this.mountedData();
  },
};
</script>
